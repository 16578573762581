import { takeLatest, all, call, put } from 'typed-redux-saga/macro';

import { getCategoriesAndDocuments } from '../../utils/firebase/firebase.utils';

import { fetchCategoriesSuccess, fetchCategoriesFailed } from './category.action';
import { CATEGORIES_ACTION_TYPES } from './category.types';

export function* fetchCategoriesAsync() {
	try {
		const categories = yield* call(getCategoriesAndDocuments);
		yield* put(fetchCategoriesSuccess(categories));
	} catch (error) {
		/**
		 * To test - use >>> await Promise.reject(new Error('Some sort of error'));
		 * inside getCategoriesAndDocuments();
		 * and handle it properly.
		 */
		yield* put(fetchCategoriesFailed(error as Error));
	}
}

export function* onFetchCategories() {
	yield* takeLatest(CATEGORIES_ACTION_TYPES.FETCH_CATEGORIES_START, fetchCategoriesAsync);
}

export function* categoriesSaga() {
	yield* all([call(onFetchCategories)]);
}
