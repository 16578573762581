import { SpinnerOverlay, SpinnerContainer } from './spinner.styles';

const Spinner = () => {
	return (
		<SpinnerOverlay>
			<SpinnerContainer data-testid='spinner' />
		</SpinnerOverlay>
	);
};

export default Spinner;
