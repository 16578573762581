import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
    :root {
        --scroll-size: 8px;
        --scroll-radius: 8px;
        --scroll-track-margin: 0.5em;
        --scroll-track-color: transparent;
        --scroll-thumb: none;
        --scroll-thumb-color: rgb(0 0 0 / 30%);
        --scroll-thumb-hover-color: rgb(0 0 0 / 40%);
    }

    ::-webkit-scrollbar {
        width: var(--scroll-size);
        height: var(--scroll-size);
    }

    ::-webkit-scrollbar-track {
        background-color: var(--scroll-track-color);
        border-radius: var(--scroll-radius);
        margin-block: var(--scroll-track-margin);
    }

    ::-webkit-scrollbar-thumb {
        background-color: var(--scroll-thumb-color);
        background-image: var(--scroll-thumb);
        border-radius: var(--scroll-radius);
    }

    ::-webkit-scrollbar-thumb:hover {
        background-color: var(--scroll-thumb-hover-color);
    }

    * {
        box-sizing: border-box;
        scrollbar-color: var(--scroll-thumb-color) var(--scroll-track-color);
        scrollbar-width: thin;
    }

    body {
        margin: 0;
        padding: 20px 40px;
        font-family: 'Open Sans Condensed', sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;

        @media screen and (max-width: 800px) {
            padding: 10px 20px;
        }
    }

    code {
        font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
    }

    #root {
        max-width: 1280px;
        margin: auto;
    }

    a {
        text-decoration: none;
        color: black;
    }
`;
