import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import { COUNTRIES_DATA } from '../../countries-data';

// const SENTRY = process.env.NODE_ENV === 'production';
const SENTRY = false;

const initialize = () => {
	if (SENTRY) {
		Sentry.init({
			dsn: process.env.REACT_APP_SENTRY_DSN,
			replaysSessionSampleRate: 1.0,
			replaysOnErrorSampleRate: 1.0,
			integrations: [
				new BrowserTracing(),
				new Sentry.Replay({
					maskAllText: false,
					blockAllMedia: false
				})
			],
			tracesSampleRate: 1.0,
			environment: process.env.NODE_ENV,
			release: `${process.env.REACT_APP_NAME}-${process.env.REACT_APP_VERSION}`
		});
	}
};

const setReferrer = () => {
	if (SENTRY) {
		const referrer = document.referrer;

		if (referrer) {
			Sentry.setTag('geo-referrer', referrer);
		}
	}
};

const setResolution = () => {
	if (SENTRY) {
		const resolution =
			window.screen.width * window.devicePixelRatio + 'x' + window.screen.height * window.devicePixelRatio;

		Sentry.setTag('resolution', resolution);
	}
};

const setGeolocation = () => {
	if (SENTRY) {
		fetch(`${process.env.REACT_APP_IPIFY_URL}`)
			.then((response) => response.json())
			.then((responseJson) => responseJson.ip)
			.then((ip) => {
				fetch(`https://ipinfo.io/${ip}?token=${process.env.REACT_APP_IPINFO_TOKEN}`)
					.then((response) => response.json())
					.then((responseJson) => {
						// Set each data as a custom tag in Sentry
						Object.keys(responseJson).forEach((key) => {
							const value = responseJson[key];

							if (value) {
								if (key === 'country') {
									Sentry.setTag('geo-' + key, COUNTRIES_DATA[value as keyof typeof COUNTRIES_DATA]);
								} else if (key === 'loc') {
									const split = value.split(',');
									Sentry.setTag('geo-latitude', split[0]);
									Sentry.setTag('geo-longitude', split[1]);
								} else {
									Sentry.setTag('geo-' + key, value);
								}
							}
						});

						Sentry.captureMessage(
							'Page View (' +
								process.env.REACT_APP_NAME +
								'): ' +
								COUNTRIES_DATA[responseJson.country as keyof typeof COUNTRIES_DATA]
						);
					})
					.catch((error) => {
						Sentry.captureException(error);
					});
			})
			.catch((error) => {
				Sentry.captureException(error);
			});
	}
};

export const initializeSentry = () => {
	if (SENTRY) {
		// Initialize Sentry
		initialize();

		// Set the referrer if any as a custom tag in Sentry
		setReferrer();

		// Set the screen's resolution as a custom tag in Sentry
		setResolution();

		// Set the user's geolocation information as custom tags in Sentry
		setGeolocation();
	}
};

export const sentryMessage = (message: string): void => {
	if (SENTRY) {
		Sentry.captureMessage(message);
	}
};

export const sentryError = (error: Error): void => {
	if (SENTRY) {
		Sentry.captureException(error);
	}
};
