import { AnyAction } from 'redux';

import { UserData } from '../../utils/firebase/firebase.utils';

import { signInFailed, signInSuccess, signOutFailed, signOutSuccess, signUpFailed } from './user.action';

export type UserState = {
	readonly currentUser: UserData | null;
	readonly isLoading: boolean;
	readonly error: Error | null;
};

export const USER_INITIAL_STATE: UserState = {
	currentUser: null,
	isLoading: false,
	error: null
};

export const userReducer = (state = USER_INITIAL_STATE, action: AnyAction): UserState => {
	if (signInSuccess.match(action)) {
		return {
			...state,
			currentUser: action.payload,
			isLoading: false
		};
	}

	if (signOutSuccess.match(action)) {
		return {
			...state,
			currentUser: null,
			isLoading: false
		};
	}

	if (signUpFailed.match(action) || signInFailed.match(action) || signOutFailed.match(action)) {
		return {
			...state,
			error: action.payload,
			isLoading: false
		};
	}

	return state;
};
